export const protocolType = {
  ws: "wss://",
  http: "http://",
  https: "https://",
};

export const domain = {
  partner: "partner.smartpost.kg",
  staging: "staging.smartpost.kg",
  ip: "64.227.126.0",
};
