import React, { useCallback, useEffect, useState } from "react";
import {
  CModal,
  CModalBody,
  CButton,
  CLabel,
} from "@coreui/react";
import { useConfirmModal, useModalState } from "src/helpers/useModal";
import OrderDetails from "src/pages/orders/Order-Details/OrderDetails";
import { useTranslation } from "react-i18next";
import {
  orderConfirmFetch,
  orderCourierChangeFetch,
  orderStatusChangeFetch,
  orderUngroupFetch,
  updateOrderFetch,
} from "src/services/OrderService";
import {
  orderProcessPool,
  orderRemoveFromPool,
  orderReturnToPool,
  orderTransferToPool,
} from "src/services/OrderService";
import { toastify } from "src/helpers/toast";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "src/redux/actions/orderActions";
import CIcon from "@coreui/icons-react";
import { useHistory } from "react-router";
import { fetchOffices } from "src/services/CompanyService";
import Select from "react-select";
import OrderRow from "./OrderRow";

export default function OrdersTable(props) {
  const hexToRgb = (hex) => {
    let arr = hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
    return `rgb(${arr[0] + 40}, ${arr[1] + 40}, ${arr[2] + 40})`;
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { isOpen, onToggle } = useModalState();
  const { onConfirmOpen, onConfirmClose, isConfirmOpen, onConfirmToggle } =
    useConfirmModal();

  const company = useSelector((state) => state.auth.company);
  const profile = useSelector((state) => state.auth.profile);

  const selectedOrders = useSelector((state) => state.order.selectedOrders);
  const couriers = useSelector((state) => state.courier.couriers);
  const [offices, setOffices] = useState([]);

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [newOrderValue, setNewOrderValue] = useState(null);
  const [field, setField] = useState(null);
  const [condCursor, setCondCursor] = useState("pointer");

  useEffect(() => {
    fetchOffices().then(
      (res) =>
        res.ok &&
        res.json().then((d) => {
          setOffices(d);
        })
    );

    return () => console.log("clearing");
  }, []);

  const openDetails = (order) => {
    setSelectedOrder(order);
    onToggle();
  };

  const openConfirmChange = (order, field, newValue) => {
    setSelectedOrder(order);
    setNewOrderValue(newValue);
    setField(field);
    onConfirmOpen();
  };

  const confirmChange = () => {
    switch (field) {
      case "status":
        confirmStatusChange();
        break;
      case "courier":
        confirmCourierChange();
        break;
      case "office":
        confirmOfficeChange();
        break;
      default:
        console.log("confirmation");
    }
  };
  const confirmStatusChange = () => {
    orderStatusChangeFetch(selectedOrder.id, newOrderValue)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Operation succeeded"));
          onConfirmClose();
          setSelectedOrder(null);
          setNewOrderValue(null);
          setField(null);
        } else {
          toastify("error", t("Operation failed"));
        }
      })
      .catch((err) => toastify("error", "Error occured"));
  };

  const confirmCourierChange = () => {
    orderCourierChangeFetch(
      selectedOrder.id,
      newOrderValue === "Никто" ? null : newOrderValue
    )
      .then((res) => {
        if (res.ok) {
          onConfirmClose();
          setSelectedOrder(null);
          setNewOrderValue(null);
          setField(null);
          toastify("success", t("Operation succeeded"));
        } else {
          toastify("error", t("Operation failed"));
        }
      })
      .catch((err) => {
        console.log(err);
        toastify("error", t("Operation failed"));
      });
  };

  const confirmOfficeChange = () => {
    updateOrderFetch(selectedOrder.id, {
      delivery_offices: newOrderValue.map((v) => v.id),
    })
      .then((res) => {
        if (res.ok) {
          onConfirmClose();
          setSelectedOrder(null);
          setNewOrderValue(null);
          setField(null);
          toastify("success", t("Operation succeeded"));
        } else {
          toastify("error", t("Operation failed"));
        }
      })
      .catch((err) => {
        console.log(err);
        toastify("error", t("Operation failed"));
      });
  };

  const ungroupOrder = (order) => {
    orderUngroupFetch(order.id)
      .then((res) => {
        if (res.ok) {
          res.json().then((d) => {
            console.log(d);
            toastify("success", t("Operation succeeded"));
            dispatch(getOrders());
          });
        } else {
          toastify("error", t("Operation failed"));
        }
      })
      .catch((err) => {
        console.log(err);
        toastify("error", "Error occured");
      });
  };
  // pool actions

  const transferToPool = (id) => {
    poolAction(orderTransferToPool(id));
  };

  const removeFromPool = (id) => {
    poolAction(orderRemoveFromPool(id));
  };

  const processPoolOrder = (id) => {
    poolAction(orderProcessPool(id), { type: "process", id: id });
  };

  const confirmPoolOrder = (order) => {
    // poolAction(orderConfirmPool(id))
    poolAction(
      orderConfirmFetch(order.id, {
        tariff_id: order.tariff?.id,
        package_type_id: order.package_type?.id,
      })
    );
  };

  const returnToPool = (id) => {
    poolAction(orderReturnToPool(id));
  };

  const poolAction = (fetch, action) => {
    if (condCursor !== "wait") {
      setCondCursor("wait");
      fetch
        .then((res) => {
          setCondCursor("pointer");
          if (res.ok) {
            if (action?.type === "process") {
              history.push("/confirm-order/" + action.id);
            } else {
              toastify("success", t("Operation succeeded"));
            }
          } else {
            res.json().then((d) => {
              console.log(d?.detail?.toString());
              toastify("error", d?.detail?.toString());
              setCondCursor("pointer");
            });
          }
        })
        .catch((err) => {
          setCondCursor("pointer");
          console.log(err);
          toastify("error", err.toString());
        });
    }
  };

  const allowedToUpdate = useCallback((order) => {
    const profileRule = profile.roles.includes('operator') && profile.delivery_office.id !== order.office_id;
    const ruleStatuses = [
      'on carrier', 
      'at destination sorting center', 
      'on final courier', 
      'arrived at receiver', 
      'on wait',
      'cancelled',
      'completed'
    ];
    const statusRule = ruleStatuses.includes(order.status);
    return !(profileRule && statusRule)
  }, [profile])

  return (
    <div>
      <table className="table table-responsive-lg">
        <thead>
          <tr>
            <th>#</th>
            <th>
              {t("Sender")} <CIcon name="cilArrowRight" /> {t("Receiver")}
            </th>
            {!profile?.roles?.includes("partner") && <th>{t("Офис")}</th>}
            <th>{t("Courier")}</th>
            <th>{t("Status")}</th>
            <th>{t("Date")}</th>
            <th>{t("Tariff")}</th>
            <th>{t("Redemption")}</th>
            <th>{t("Price")}</th>
            {!props.archive && <th>{t("Actions")}</th>}
          </tr>
        </thead>
        <tbody>
          {props.currentOrders.length > 0 &&
            props.currentOrders.map((order) => (
              <OrderRow
                key={order.id}
                allowedToUpdate={allowedToUpdate(order)}
                order={order}
                hexToRgb={hexToRgb}
                statusList={props.statusList}
                openDetails={openDetails}
                profile={profile}
                openConfirmChange={openConfirmChange}
                couriers={couriers}
                company={company}
                processPoolOrder={processPoolOrder}
                confirmPoolOrder={confirmPoolOrder}
                isArchive={props.archive}
                ungroupOrder={ungroupOrder}
                selectedOrders={selectedOrders}
                dispatch={dispatch}
                transferToPool={transferToPool}
                removeFromPool={removeFromPool}
                returnToPool={returnToPool}
                condCursor={condCursor}
              />
            ))}
        </tbody>
      </table>
      {selectedOrder && (
        <OrderDetails
          allowedToUpdate={allowedToUpdate(selectedOrder)}
          isOpen={props.selectedOrderId ? true : isOpen}
          onToggle={onToggle}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
        />
      )}

      {selectedOrder && (
        <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
          <CModalBody>
            {field === "office" ? (
              <>
                <CLabel>{t("Offices")}</CLabel>
                <Select
                  isMulti
                  className="my-2"
                  options={offices.map((o) => {
                    return { label: o.name, value: o.id, ...o };
                  })}
                  onChange={(res) => setNewOrderValue(res)}
                  value={[...newOrderValue]}
                />
              </>
            ) : (
              <h5 className="text-center">
                {t("Confirm")} {field} change?
              </h5>
            )}
            <div className="text-right">
              <CButton
                variant="outline"
                color="dark"
                onClick={onConfirmToggle}
                className="mx-2"
              >
                {t("Close")}
              </CButton>
              <CButton
                variant="outline"
                color="primary"
                onClick={confirmChange}
              >
                {t("Confirm")}
              </CButton>
            </div>
          </CModalBody>
        </CModal>
      )}
    </div>
  );
}
